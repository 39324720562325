export const formatSelectItems = (items, label, value, extraField) => {
	return items?.length > 0
		? items.map((sessionData) => {
				const newObj = {};
				if (Array.isArray(value)) {
					value.forEach((item) => {
						newObj[item] = sessionData[item];
					});
				}

				const baseLabel = Array.isArray(label)
					? `${sessionData[label[0]]} (${sessionData[label[1]]})`
					: sessionData[label];

				// Append extraField if it exists
				const finalLabel = extraField
					? `${baseLabel} - ${sessionData[extraField]}`
					: baseLabel;

				return {
					value: Array.isArray(value) ? newObj : sessionData[value],
					label: finalLabel
				};
		  })
		: [];
};
